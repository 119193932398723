<template>
  <div class="hd-record">
    <VToolbar dense>
      <VBtn text class="px-0" style="min-width: 36px" @click="$router.back()">
        <VIcon>mdi-arrow-left</VIcon>
      </VBtn>
      <VDivider vertical />
      <VToolbarTitle>
        {{ menuItems.find((o) => o.name === $route.name).title }}
      </VToolbarTitle>
      <VSpacer />
      <VBtnToggle dense borderless multiple :max="0">
        <VBtn><VIcon>mdi-chevron-left</VIcon></VBtn>
        <VBtn>A123456789 王小明</VBtn>
        <VBtn><VIcon>mdi-chevron-right</VIcon></VBtn>
      </VBtnToggle>
      <VBtnToggle
        v-if="$route.name !== 'hd-record-special-shift'"
        dense
        borderless
        multiple
        :max="0"
      >
        <VBtn><VIcon>mdi-chevron-left</VIcon></VBtn>
        <VBtn>2021年07月01日</VBtn>
        <VBtn><VIcon>mdi-chevron-right</VIcon></VBtn>
      </VBtnToggle>
      <VSpacer />
      <VDivider vertical />
      <VBtn depressed color="primary">儲存</VBtn>
    </VToolbar>
    <VFadeTransition mode="out-in">
      <RouterView :key="$route.name" style="max-height: calc(100vh - 144px)" />
    </VFadeTransition>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDRecord',
  // beforeRouteLeave(to, from, next) {
  //   this.yesAction = () => next();
  //   this.noAction = () => next(false);
  //   this.$_mixin_messageDialog_open(
  //     MessageDialogType.warning,
  //     '警告',
  //     '請問不保留當前變更嗎？',
  //     MessageDialogButtons.yesNo,
  //   );
  // },
  data: () => ({
    menuItems: [
      {
        title: '透析醫囑',
        name: 'hd-record-medical-orders',
      },
      {
        title: '護理紀錄',
        name: 'hd-record-nursing-record',
      },
      {
        title: '透析中給藥',
        name: 'hd-record-dosing-in-hd',
      },
      {
        title: '特殊交班',
        name: 'hd-record-special-shift',
      },
    ],
  }),
});
</script>

<style lang="scss">
.hd-record {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }
}
</style>
